/* eslint-disable prettier/prettier */
import {
  parseSearchState,
  SearchProvider,
  formatSearchState,
} from '@faststore/sdk'
import axios from 'axios'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { useMemo } from 'react'
import Breadcrumb from 'src/components/sections/Breadcrumb'
import ProductGallery from 'src/components/sections/ProductGallery'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import type {
  ClusterPageQueryQuery,
  ClusterPageQueryQueryVariables,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import type { SearchState } from '@faststore/sdk'
import 'src/styles/pages/plp.scss'
import { useSession } from 'src/sdk/session'
// import type { RawDraftContentState } from 'draft-js'

import { api } from '../../../store.config'

interface ServerDataResponse {
  cluster: { name: string; id: string }
}

type Props = PageProps<
  ClusterPageQueryQuery,
  ClusterPageQueryQueryVariables,
  unknown,
  ServerDataResponse
> & { slug: string }

const useSearchParams = (props: Props): SearchState => {
  const {
    location: { href, pathname },
    serverData,
  } = props

  const selectedFacets = [
    { key: 'productClusterIds', value: serverData?.cluster?.id },
  ]

  const hrefState = useMemo(() => {
    const newState = parseSearchState(
      new URL(href ?? pathname, 'http://localhost')
    )

    // In case we are in an incomplete url
    if (newState.selectedFacets.length === 0) {
      newState.selectedFacets = selectedFacets ?? []
    }

    return formatSearchState(newState).href
  }, [href, pathname, selectedFacets])

  return useMemo(() => parseSearchState(new URL(hrefState)), [hrefState])
}

function Page(props: Props) {
  const {
    data: {
      site,
      // allCmsPromotionflags: {
      //   edges: [
      //     {
      //       node: {
      //         sections: [
      //           {
      //             data: { content },
      //           },
      //         ],
      //       },
      //     },
      //   ],
      // },
    },
    serverData,
    slug,
  } = props

  // const parsedContent = JSON.parse(content) as RawDraftContentState
  // const flagsCSS = parsedContent.blocks
  //   .map((block) => (!block.text.trim() && '\n') || block.text)
  //   .join('\n')

  const { locale } = useSession()
  const searchParams = useSearchParams(props)

  // No data was found
  if (serverData === null) {
    return null
  }

  const cluster = serverData?.cluster
  const { page } = searchParams
  const title: string = cluster?.name ?? site?.siteMetadata?.title ?? ''
  const pageQuery = page !== 0 ? `?page=${page}` : ''
  const canonical = `${site?.siteMetadata?.siteUrl}/${slug}${pageQuery}`

  const breadcrumbList = [{ item: '/', name: title, position: 1 }]

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* <style type="text/css" dangerouslySetInnerHTML={{ __html: flagsCSS }} /> */}
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        description={site?.siteMetadata?.description ?? ''}
        canonical={canonical}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <BreadcrumbJsonLd itemListElements={breadcrumbList} />

      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}
      <Breadcrumb breadcrumbList={breadcrumbList} name={title} />
      <div data-fs-category-results>
        <ProductGallery title={title} collectionName={cluster?.name} useFilters />
      </div>
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query ClusterPageQuery {
    allCmsPromotionflags {
      edges {
        node {
          sections {
            data
          }
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
  }
`

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
}) => {
  const query = `query {
    collection (id: ${slug}) @context(provider: "vtex.catalog-graphql") {
      name
    	id
      cacheId
      status
    }
  }`

  try {
    const { data }: { data: ClustersApiResponse } = await axios.post(
      `https://${api.storeId}.myvtex.com/_v/private/graphql/v1`,
      { query },
      {
        headers: {
          Accept: 'application/vnd.vtex.ds.v10+json',
          'Content-Type': 'application/json',
        },
      }
    )

    const cluster = data?.data?.collection

    // not found
    if (!data) {
      return {
        status: 404,
        props: null,
        headers: {
          location: `/404/?${slug}`,
        },
      }
    }

    return {
      props: { cluster },
    }
  } catch (error) {
    // api error
    return {
      status: 400,
      props: null,
      headers: {
        location: `/404/?${slug}`,
      },
    }
  }
}

Page.displayName = 'Page'
export default mark(Page)

export { Head } from 'src/components/common/Head'
